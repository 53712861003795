<template>
  <div class="common-layout">
    <el-container>
      <el-aside width="200px">
        <p class="subtitle">运维练兵场</p>
        <el-image src="http://cdn.azlinux.com/sre/bg/sre_logo_trans.png" alt="SRE azlinux site logo"
          style="width: 78px; height: 78px"></el-image>
      </el-aside>
      <el-main>
        <el-descriptions title="常见问题" :column="1" border>
          <el-descriptions-item label="网站定位，面向的用户群体?">
            <p>主要是服务于IT行业的运维人员，系统管理员类型的工程师。</p>
            <p>
              构建理论+实践型运维人才。 SRE = 高可用系统管理能力 + 软件工程能力
            </p>
            <p>
              网站精心设计了实战场景，收集了大厂中厂的运维典型案例，并做成了交互式实验模型。
            </p>
            <p>
              通过完成这些生产级别的运维演练，您的系统管理/运维技能一定会得到提高。
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="网站所有的资源都免费使用吗？">
            <p>部分严重消耗云计算资源的练习项目需要收费。</p>
            <p>
              例如，您在练习zookeeper节点的扩容缩容时候，由3个节点无损热扩容为5个节点时.
            </p>
            <p>
              需要耗费一些计算资源(可能是1核心2GB内存配置的虚拟机，可能是容器)
              对于这样的练习，本网站适当收取一些费用。
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="账号是否可以共享给其他人使用?">
            <p>共享账号会带来资源争抢，产生异常！</p>
            <p>
              一个账号某时刻只能绑定一个运行中的资源，如果同时登录，那么会产生资源中断，影响您当前的正常使用。
            </p>
            <p>本网站会员费用价格不高，短期使用可购买月度/季度会员。</p>
          </el-descriptions-item>
          <el-descriptions-item label="能不能送我免费的VIP会员?">
            <p>如果你认识站长的话，那么就可以获赠月度VIP会员。</p>
          </el-descriptions-item>
          <el-descriptions-item label="学完全部内容需要多久?">
            <p>可能需要持续学习1年以上，甚至2年等。</p>
            <p>不建议地毯式学习。建议您挑选您可能工作中用到的技术进行学习。</p>
          </el-descriptions-item>
          <el-descriptions-item label="不满意是否可退费?">
            <p>
              是的，可以退费。退费时，按天计算已消耗的资源，根据剩余金额，退还。
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="我有疑问，是否能提供答疑服务？">
            <p>
              1. 您通过打开关于页面
              <a href="/about-us">【关于我们】</a>，查看到联系方式发送疑问即可，如通过微信。 2.
              您可以到【运维问答】板块免费发表您的疑问，站长看到问题后，有时间会回答。其他用户也可以回答。
            </p>
            <p>
              较复杂的问题，目前精力有限，暂不保证100%及时（24小时内）回复。
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="其他问题?">
            <p>
              您可以发送您的问题/咨询/建议等到邮箱 azlinux@azlinux.com
              ，我们收到信息后会尽快回复。
            </p>
            <p>谢谢您的支持。</p>
          </el-descriptions-item>
        </el-descriptions>
      </el-main>
    </el-container>
  </div>
</template>

<script>
// ... (JavaScript 代码保持不变)
</script>

<style scoped>
.subtitle {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.el-descriptions {
  margin-top: 2rem;
}

.el-descriptions__header {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.el-descriptions__body {
  /* 添加适当的背景色或边框 */
  border: 1px solid #ebeef5;
  border-radius: 4px;
  padding: 1rem;
}

.el-descriptions-item__label {
  font-weight: bold;
}

.el-descriptions-item__content p {
  margin-bottom: 0.5rem;
}
</style>
