<template>
  <div class="container">
    <div class="winouter">
      <div class="flex items-center">
        <el-select v-model="selectedWindows" placeholder="版本" class="version-select" size="small">
          <el-option v-for="version in windowsVersions" :key="version" :label="version" :value="version" />
        </el-select>

        <el-select v-model="selectedSpec" placeholder="规格" class="spec-select" size="small">
          <el-option v-for="spec in specifications" :key="spec" :label="spec" :value="spec" />
        </el-select>

        <el-tooltip content="设置C盘大小等" placement="bottom" effect="dark">
          <el-button @click="openEnvironmentOptions" type="info">
            环境选项
          </el-button>
        </el-tooltip>
        <el-dialog title="选择系统盘大小(GB)" v-model="dialogVisible" width="30%">
          <el-input-number v-model="systemDiskSize" :min="40" :max="1000" label="系统盘大小" size="small"
            :step="10"></el-input-number>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false">取消</el-button>
              <el-button type="primary" @click="saveSystemDiskSize">
                确定
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-tooltip content="Windows Server初始化时间稍长，可能超过1分钟。" placement="bottom" effect="dark">
          <el-button type="success" @click="prepareWindows">
            准备环境
          </el-button>
        </el-tooltip>

        <el-tooltip content="如您不再继续使用，可销毁，注意保存数据" placement="bottom" effect="dark">
          <el-button type="danger" @click="destroyWindows">
            销毁环境
          </el-button>
        </el-tooltip>
        <DelayReleaseButton @updateReleaseTime="handleUpdateReleaseTime"
          :delayReleaseButtonEnabled="isDelayReleaseButtonEnabled" />

        <div style="
            display: inline-flex;
            margin-left: 8px;
            background-color: wheat;
            padding: 4px;
            border-radius: 4px;
          ">
          <el-tooltip class="box-item" effect="dark" content="创建磁盘并挂载，单盘数值为40~1000 GB" placement="top">
            <el-button @click="showInput = !showInput" color="#626aef" :disabled="!isDataDiskEnabled">
              {{ showInput ? "隐藏1" : "挂盘1" }}
            </el-button>
          </el-tooltip>
          <div v-if="showInput">
            <el-input v-model.number="diskSize" placeholder="40" min="40" max="1000" style="width: 50px">
            </el-input>

            <el-button @click="confirmAndCreate" color="#626aef">
              挂载1
            </el-button>
          </div>
          <el-tooltip class="box-item" effect="dark" content="对第一块盘进行扩容，数值应大于原始数值！" placement="top">
            <el-button @click="showInputExpand = !showInputExpand" color="#01579b" :disabled="!isNewDiskSizeEnabled">
              {{ showInputExpand ? "隐藏1" : "扩盘1" }}
            </el-button>
          </el-tooltip>
          <div v-if="showInputExpand">
            <el-input v-model.number="diskNewSize" placeholder="40" min="40" max="1000" style="width: 50px">
            </el-input>
            <el-button @click="confirmAndExpand" color="#01579b">
              扩容1
            </el-button>
          </div>
        </div>

        <div style="
            display: inline-flex;
            margin-left: 8px;
            background-color: #b2dfdb;
            padding: 4px;
            border-radius: 4px;
          ">
          <el-tooltip class="box-item" effect="dark" content="创建磁盘并挂载硬盘2，单盘数值为40~1000 GB" placement="top">
            <el-button @click="showInput2 = !showInput2" color="#626aef" :disabled="!isDataDisk2Enabled">
              {{ showInput2 ? "隐藏2" : "挂盘2" }}
            </el-button>
          </el-tooltip>
          <div v-if="showInput2">
            <el-input v-model.number="diskSize2" placeholder="50" min="50" max="1000" style="width: 50px">
            </el-input>

            <el-button @click="confirmAndCreate2" color="#626aef">
              挂载2
            </el-button>
          </div>
          <el-tooltip class="box-item" effect="dark" content="对第2块盘进行扩容，数值应大于原始数值！" placement="top">
            <el-button @click="showInputExpand2 = !showInputExpand2" color="#01579b" :disabled="!isNewDiskSize2Enabled">
              {{ showInputExpand2 ? "隐藏2" : "扩盘2" }}
            </el-button>
          </el-tooltip>
          <div v-if="showInputExpand2">
            <el-input v-model.number="diskNewSize2" placeholder="60" min="60" max="1000" style="width: 50px">
            </el-input>
            <el-button @click="confirmAndExpand2" color="#01579b">
              扩容2
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="content-zone">
      <div class="box-card" shadow="hover">
        <div class="status-container">
          <p class="status-message">
            Windows Server 状态:
            <span class="status">{{ windowsStatus }}</span>
            <span v-if="release_datetime" class="divider">|</span>
            <span v-if="release_datetime" class="release-datetime">
              释放时间: {{ release_datetime }}
            </span>
          </p>
        </div>

        <div class="command-container">
          <p style="font-size: 14px">
            Windows系统: 点击【复制】按钮，打开Cmd命令行，运行。账号:
            {{ username }}
          </p>
          <el-input v-model="mstscCommand" readonly size="large" class="command-input"
            style="flex: 1; margin-right: 10px; max-width: 320px; color: gray"></el-input>
          <el-tooltip content="复制该命令后，打开命令行，输入账号Administrator，启动桌面连接" placement="bottom" effect="dark">
            <el-button type="primary" @click="copyToClipboard" :disabled="thost === '0.0.0.0'"> 复制 </el-button>
          </el-tooltip>
          <el-tooltip content="下载RDP文件后，双击打开，密码是您的sre密码。环境启动耗时约1-2分钟。" placement="bottom" effect="dark">
            <el-button type="primary" @click="downloadRDP" :disabled="thost === '0.0.0.0'">下载 RDP 文件（建议）</el-button>
          </el-tooltip>
        </div>
      </div>
    </div>

    <div class="cmd-zone">
      <WindowsCmdDemo :initialCommand="mstscCommand" />
    </div>
  </div>
</template>

<script>
import {
  ElSelect,
  ElOption,
  ElButton,
  ElInputNumber,
  ElMessage,
  ElMessageBox,
} from "element-plus";
import axios from "axios";
import DelayReleaseButton from "./base/DelayReleaseButton.vue";
import WindowsCmdDemo from "./base/WindowsCmdDemo";

export default {
  name: "OnlineWindows",
  components: {
    ElSelect,
    ElOption,
    ElButton,
    ElInputNumber,
    WindowsCmdDemo,
    DelayReleaseButton,
  },
  data() {
    return {
      showInput: false,
      showInput2: false,
      showInputExpand: false,
      showInputExpand2: false,
      diskSize: 40,
      diskNewSize: 50,
      diskSize2: 50,
      diskNewSize2: 60,
      resId: 20061,
      release_datetime: "",
      tport: "3389",
      dialogVisible: false,
      isDestroyWindowsEnabled: false,
      isDelayReleaseButtonEnabled: true,
      isDataDiskEnabled: !false,
      isDataDisk2Enabled: !false,
      isNewDiskSizeEnabled: !false,
      isNewDiskSize2Enabled: !false,
      windowsStatus: "未创建",
      thost: "0.0.0.0",
      username: "Administrator",
      //   passwd: "YourPassword",
      mstscCommand: "",
      windowsVersions: [
        "2022_21H2_dtc_zh-cn_uefi",
        "2022_21H2_dtc_en-us_uefi",
        "2022_21H2_dtc_zh-cn",
        "2022_21H2_dtc_en-us",
        "2019_1809_dtc_zh-cn",
        "2019_1809_dtc_en-us",
        "2016_1607_dtc_en-us",
        "2016_1607_dtc_zh-cn",
        "2019_1809_dtc_zh-cn_uefi",
        "2019_1809_dtc_en-us_uefi",
        "2012r2_9600_dtc_zh-cn",
        "2012r2_9600_dtc_en-us",
        "2008r2_sp1_ent_zh-cn",
        "2008r2_sp1_ent_en-us",
      ],
      specifications: [
        "2C4G",
        "2C8G",
        "4C8G",
        "4C16G",
        "4C32G",
        "8C16G",
        "8C32G",
      ],
      selectedWindows: "2022_21H2_dtc_zh-cn",
      selectedSpec: "2C4G",
      systemDiskSize: 40,
    };
  },
  mounted() {
    this.updateMstscCommand();
  },
  methods: {
    getApiHost() {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
        return 'hk.azlinux.com';
      }
      return 'sre.azlinux.com';
    },
    downloadRDP() {
      const rdpContent = `full address:s:${this.thost}\nusername:s:${this.username}\nadministrative session:i:1\nauto connect:i:1`;

      const blob = new Blob([rdpContent], { type: "application/x-rdp" });

      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.thost}-${this.username}-azlinux-com.rdp`; // 指定下载文件名

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    openEnvironmentOptions() {
      this.dialogVisible = true; // 打开对话框
      console.log(this.dialogVisible);
    },
    saveSystemDiskSize() {
      this.dialogVisible = false; // 关闭对话框并保存选择
      ElMessage({
        message: `系统盘大小已设置为 ${this.systemDiskSize} GB`,
        type: "success",
        showClose: true,
        duration: 1000,
        offset: 100,
      });
    },
    updateMstscCommand() {
      this.mstscCommand = `start mstsc /v:${this.thost} /admin`;
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.mstscCommand);
        ElMessage.success("命令已复制到剪贴板");
      } catch (err) {
        ElMessage.error("复制失败: 可能是站点当前以http模式运行，请手动复制");
      }
    },
    prepareWindows() {
      const specMessage = `即将创建【${this.selectedSpec}】规格的Windows系统，<br>系统盘大小【${this.systemDiskSize} GB】`;

      ElMessageBox.confirm(specMessage, "确认创建", {
        confirmButtonText: "继续创建",
        cancelButtonText: "取消",
        type: "warning",
        dangerouslyUseHTMLString: true, // Allow HTML string
      })
        .then(() => {
          // If the user confirms, proceed with the request
          this.executePrepareWindows();
        })
        .catch(() => {
          // If the user cancels, do nothing
          console.log("创建环境已取消");
        });
    },
    // Actual logic to send the request
    executePrepareWindows() {
      let url = `http://${this.getApiHost()}:5028/get_online_windows`;
      let id = this.resId;
      let flavor = this.selectedSpec;
      let winver = this.selectedWindows;
      let email = localStorage.getItem("email");
      var email_user;

      if (email) {
        email_user = email.substring(4);
      } else {
        email_user = "";
        ElMessage({
          message: "检测到您尚未登录，请登录后使用，3秒后自动跳转到登录页面",
          type: "error",
          duration: 3000,
          offset: 440,
        });

        // 新增的倒计时逻辑
        setTimeout(() => {
          window.location.href = "/login"; // 跳转到登录页面
        }, 3000); // 3秒后跳转

        return;
      }

      let postData = {
        id: id,
        email: email_user,
        flavor: flavor,
        winver: winver,
        osDiskSize: this.systemDiskSize, // 包含系统盘大小
      };

      this.isDestroyWindowsEnabled = true;
      this.isDelayReleaseButtonEnabled = true;
      this.isDataDiskEnabled = true;
      this.isDataDisk2Enabled = true;
      this.isNewDiskSizeEnabled = true;
      this.isNewDiskSize2Enabled = true;

      this.release_datetime = "等待数据...";
      this.windowsStatus = "启动中...";

      axios
        .post(url, postData)
        .then((response) => {
          this.responseData = response.data;
          console.log(response.data);
          if (response.data.vip_status == 0) {
            ElMessageBox.confirm("您的账号VIP状态已过期，需充值续费", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                console.log("MessageBox OK");
              })
              .catch(() => {
                console.log("MessageBox Cancel/Close");
              });
            return;
          }
          this.thost = this.responseData.data.thost;
          this.port = this.responseData.data.port;
          this.lname = this.responseData.data.username;
          this.passwd = this.responseData.data.passwd.slice(7);
          this.release_datetime = this.responseData.data.release_datetime;
          this.windowsStatus = "已创建";

          this.updateMstscCommand();
        })
        .catch((error) => {
          console.log(error.response.data);
          this.hint2 =
            "------------------------ <br>" +
            "旧环境ip: " +
            error.response.data.ip_address +
            "<br>" +
            "账户名【sre/Administrator(Windows)】<br>" +
            "和密码【azLinux.com3】(默认)（或[个人中心]查看）<br>" +
            "释放时间: 【" +
            error.response.data.release_datetime +
            "】";
          this.thost = error.response.data.ip_address;
          this.release_datetime = error.response.data.release_datetime;
          this.passwd = error.response.data.passwd;
          this.updateMstscCommand();
          if (error.response.data.status == "error_exists") {
            this.windowsStatus = "已创建";
            ElMessageBox.alert(
              "1. 旧资源存在，请连接【远程桌面】！<br>" +
              "2. 或者【销毁环境】，再执行【准备环境】。<br> " +
              this.hint2,
              "提示",
              {
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                  console.log("Clicked OK", action);
                },
              }
            );
          }
        });
    },
    async destroyWindows() {
      // Show confirmation dialog
      try {
        await ElMessageBox.confirm(
          '即将销毁当前Windows虚拟机，注意保存数据！', // Confirmation message
          '确认销毁', // Title of the message box
          {
            confirmButtonText: '确认销毁', // Text for the confirm button
            cancelButtonText: '取消', // Text for the cancel button
            type: 'error', // Type of the message box (error color)
          }
        );

        // If the user clicked "确认销毁", proceed with the request
        console.log("Destroying online Windows server...");

        const email_user = localStorage.getItem("email");
        if (!email_user) {
          ElMessage({
            message: "发生了异常，建议退出账号，重新登录",
            type: "error",
            duration: 2000,
            offset: 240,
          });
          return;
        }

        let real_user = email_user.substring(4);
        const sec_code = real_user.substring(0, 2) + "98" + "yCqJWmqW";

        const response = await axios.post(
          `http://${this.getApiHost()}:5028/delete_online_windows`,
          {
            email: real_user, // Send email_user data
            sec_code: sec_code,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // Handle successful request
        this.isDestroyWindowsEnabled = false;
        this.isDelayReleaseButtonEnabled = false;
        this.isDataDiskEnabled = false;
        this.isDataDisk2Enabled = false;
        this.isNewDiskSizeEnabled = false;
        this.isNewDiskSize2Enabled = false;
        this.windowsStatus = "已销毁";
        this.thost = "0.0.0.0";
        this.release_datetime = "";

        this.updateMstscCommand();

        ElMessage({
          message: "销毁Windows Server成功，您可以继续其他操作。",
          type: "success",
          duration: 2000,
          offset: 240,
        });
        console.log("Windows server destroy response: ", response);
      } catch (error) {
        // Handle the case when the user clicks "取消"
        if (error === 'cancel') {
          console.log('用户取消了操作'); // Optional: log the cancellation
          return; // Just close the dialog, no further action needed
        }

        // Handle errors from the request
        if (error.response) {
          if (error.response.status === 404) {
            ElMessage({
              message: "未找到用户的当前实例，无需销毁。",
              type: "warning",
              duration: 2000,
              offset: 400,
            });
          } else {
            ElMessage({
              message: `服务器错误: ${error.response.data.message || "请稍后重试"}`,
              type: "error",
              duration: 2000,
              offset: 400,
            });
          }
        } else {
          // Network error or other issues
          ElMessage({
            message: "网络异常，请检查网络连接。",
            type: "error",
            duration: 2000,
            offset: 240,
          });
        }
        console.log("Error during destroy: ", error);
      }
    },
    handleUpdateReleaseTime(newReleaseTime) {
      this.release_datetime = newReleaseTime;
      console.log("New release time after delay: ", newReleaseTime);
    },
    confirmAndCreate() {
      if (
        isNaN(this.diskSize) ||
        !Number.isInteger(this.diskSize) ||
        this.diskSize < 40 ||
        this.diskSize > 1000
      ) {
        ElMessage({
          message: "请输入一个40到1000之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `您即将创建大小为 ${this.diskSize} GB 的云硬盘，并挂载到Windows，确认吗?`,
        "确认挂载",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.createAndAttachDisk();
        })
        .catch(() => { });
    },
    confirmAndExpand() {
      if (
        isNaN(this.diskNewSize) ||
        !Number.isInteger(this.diskNewSize) ||
        this.diskNewSize > 1000
      ) {
        ElMessage({
          message: "数据盘最大支持1000G",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `即将对第一块云盘扩容，容量将增加到 ${this.diskNewSize} GB，确认吗?`,
        "确认扩容",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.expandDisk();
        })
        .catch(() => { });
    },

    async createAndAttachDisk() {
      if (isNaN(this.diskSize) || !Number.isInteger(this.diskSize)) {
        ElMessage({
          message: "请输入一个有效的整数！40 ~ 1000",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize = 40;
        return;
      }
      if (this.diskSize < 40 || this.diskSize > 1000) {
        ElMessage({
          message: "新硬盘大小: 输入 40 ~ 1000 之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize = 40;
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          `http://${this.getApiHost()}:5048/attach_disk_to_windows_os`,
          {
            email: email_user.substring(4),
            diskSize: this.diskSize,
          }
        );

        if (response.data.success) {
          this.diskNewSize = this.diskSize + 10;
          ElMessage({
            message: "新硬盘挂载成功！",
            type: "success",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！" + response.data.message,
            type: "error",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message: "出现错误：" + error.response.data.message,
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    async expandDisk() {
      if (isNaN(this.diskNewSize) || !Number.isInteger(this.diskNewSize)) {
        ElMessage({
          message: "请输入一个有效的整数！且比旧的硬盘大小要大。",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        if (this.diskSize <= 990) {
          this.diskNewSize = this.diskSize + 10;
        } else {
          this.diskNewSize = 1000;
        }
        return;
      }
      if (this.diskNewSize > 1000) {
        ElMessage({
          message: "扩容到: 最大支持 1000 GB 数据盘",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        if (this.diskSize <= 990) {
          this.diskNewSize = this.diskSize + 10;
        } else {
          this.diskNewSize = 1000;
        }
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          `http://${this.getApiHost()}:5049/expand_windows_disk`,
          {
            email: email_user.substring(4),
            diskNewSize: this.diskNewSize,
          }
        );

        if (response.data.success) {
          ElMessage({
            message: response.data.message,
            type: "success",
            duration: 2000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！",
            type: "error",
            duration: 2000,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message:
            "出现错误：" + error.response.data.message + " 请检查数值大小!",
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    confirmAndCreate2() {
      if (
        isNaN(this.diskSize2) ||
        !Number.isInteger(this.diskSize2) ||
        this.diskSize2 < 40 ||
        this.diskSize2 > 1000
      ) {
        ElMessage({
          message: "请输入一个40到1000之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `您即将创建大小为 ${this.diskSize2} GB 的云硬盘，并挂载到Windows，确认吗?`,
        "确认挂载",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.createAndAttachDisk2();
        })
        .catch(() => { });
    },
    confirmAndExpand2() {
      if (
        isNaN(this.diskNewSize2) ||
        !Number.isInteger(this.diskNewSize2) ||
        this.diskNewSize2 > 1000
      ) {
        ElMessage({
          message: "数据盘最大支持1000G",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      ElMessageBox.confirm(
        `即将对第2块云盘扩容，容量将增加到 ${this.diskNewSize2} GB，确认吗?`,
        "确认扩容",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.expandDisk2();
        })
        .catch(() => { });
    },

    async createAndAttachDisk2() {
      if (isNaN(this.diskSize2) || !Number.isInteger(this.diskSize2)) {
        ElMessage({
          message: "请输入一个有效的整数！40 ~ 1000",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize2 = 40;
        return;
      }
      if (this.diskSize2 < 40 || this.diskSize2 > 1000) {
        ElMessage({
          message: "新硬盘大小: 输入 40 ~ 1000 之间的整数！",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        this.diskSize2 = 40;
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          `http://${this.getApiHost()}:5048/attach_disk2_to_windows_os`,
          {
            email: email_user.substring(4),
            diskSize: this.diskSize2,
          }
        );

        if (response.data.success) {
          this.diskNewSize2 = this.diskSize2 + 10;

          ElMessage({
            message: "新硬盘挂载成功！",
            type: "success",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！" + response.data.message,
            type: "error",
            duration: 2000,
            showClose: true,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message: "出现错误：" + error.response.data.message,
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
    async expandDisk2() {
      if (isNaN(this.diskNewSize2) || !Number.isInteger(this.diskNewSize2)) {
        ElMessage({
          message: "请输入一个有效的整数！且比旧的硬盘大小要大。",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        if (this.diskSize2 <= 990) {
          this.diskNewSize2 = this.diskSize2 + 10;
        } else {
          this.diskNewSize2 = 1000;
        }
        return;
      }
      if (this.diskNewSize2 > 1000) {
        ElMessage({
          message: "扩容到: 最大支持 1000 GB 数据盘",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        if (this.diskSize2 <= 990) {
          this.diskNewSize2 = this.diskSize2 + 10;
        } else {
          this.diskNewSize2 = 1000;
        }
        return;
      }

      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          duration: 2000,
          offset: 240,
        });
        return;
      }

      try {
        const response = await axios.post(
          `http://${this.getApiHost()}:5049/expand_windows_disk2`,
          {
            email: email_user.substring(4),
            diskNewSize: this.diskNewSize2,
          }
        );

        if (response.data.success) {
          ElMessage({
            message: response.data.message,
            type: "success",
            duration: 2000,
            offset: 240,
          });
        } else {
          ElMessage({
            message: "磁盘创建或挂载失败！",
            type: "error",
            duration: 2000,
            offset: 240,
          });
        }
      } catch (error) {
        console.log(error);
        ElMessage({
          message:
            "出现错误：" + error.response.data.message + " 请检查数值大小!",
          type: "error",
          duration: 2000,
          offset: 240,
        });
      }
    },
  },
};
</script>

<style scoped>
.winouter {
  padding: 0.4rem 0;
  background-color: #00a4ef;
}

.outer {
  display: inline-block;
}

.cmd-zone {
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}

.inline-item {
  margin: 6px;
}

.spec-select {
  width: 80px;
  /* 设置下拉框的最小宽度 */
  margin-right: 0.4rem;
}

.version-select {
  width: 220px;
  margin-right: 0.4rem;
}

.el-button {
  margin: 0 2px;
  padding: 2px;
}

.status-container {
  margin: 10px 0;
  padding: 10px;
  background-color: #f8f9fa;
  /* 浅灰色背景，使状态信息更突出 */
  border-radius: 8px;
  /* 圆角边框 */
  border: 1px solid #ddd;
  /* 边框颜色 */
}

.status-message {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.status {
  color: #28a745;
  /* 绿色，表示服务器状态良好 */
  font-weight: bold;
  margin-left: 8px;
}

.divider {
  margin: 0 12px;
  color: #999;
}

.release-datetime {
  font-size: 14px;
  color: #555;
}

.command-container {
  margin-bottom: 8px;
}
</style>
