<template>
  <div>
    <header>
      <h1>正在为您挑选最佳访问 azlinux.com 的服务器</h1>
      <h2>切换其他站点后，可能需要重新登录。账户状态不受影响。</h2>
      <div id="countdown">
        请选择站点，
        <span id="time" style="color: darkgreen; font-weight: 900">{{
          timeLeft
        }}</span>
        秒之后自动跳转
      </div>
    </header>
    <main>
      <div id="results">
        <button v-for="(server, index) in servers" :key="index" :disabled="!server.active" :style="{
          backgroundColor:
            server.delay !== null
              ? server.isBest
                ? '#28a745'
                : '#007bff'
              : '#ccc',
        }" @click="navigateTo(server.url)">
          {{ server.name }} ({{
            server.delay !== null ? server.delay + "ms" : "无响应"
          }})
        </button>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      servers: [
        {
          name: "中国",
          url: "http://azlinux.com",
          status: "on",
          delay: null,
          active: false,
          isBest: false,
        },
        {
          name: "香港",
          url: "http://hk.azlinux.com",
          status: "on",
          delay: null,
          active: false,
          isBest: false,
        },
      ],
      timeLeft: 16,
      countdown: null, // 保存定时器 ID
    };
  },
  mounted() {
    this.testDelays();
  },
  beforeUnmount() {
    if (this.countdown) {
      clearInterval(this.countdown); // 清除定时器
    }
  },
  methods: {
    async testDelays() {
      this.servers.forEach((server) => {
        server.active = true;
      });

      const results = await Promise.all(
        this.servers.map((server) =>
          server.status === "on"
            ? this.pingServer(server)
            : Promise.resolve(server)
        )
      );

      results.sort((a, b) => (a.delay || Infinity) - (b.delay || Infinity));
      this.updateResults(results);
      this.startCountdown();
    },
    pingServer(server) {
      return new Promise((resolve) => {
        const start = Date.now();
        const timeout = setTimeout(() => {
          resolve({ ...server, delay: null });
        }, 2000);

        fetch(server.url, { cache: "no-cache" })
          .then(() => {
            const delay = Date.now() - start;
            clearTimeout(timeout);
            resolve({ ...server, delay });
          })
          .catch(() => {
            clearTimeout(timeout);
            resolve({ ...server, delay: null });
          });
      });
    },
    updateResults(results) {
      this.servers = results.map((result, index) => {
        const isBest = index === 0 && result.delay !== null;
        return { ...result, active: result.delay !== null, isBest };
      });
    },
    startCountdown() {
      this.countdown = setInterval(() => {
        if (this.timeLeft <= 0) {
          clearInterval(this.countdown);
          const firstActiveServer = this.servers.find(
            (server) => server.active
          );
          if (firstActiveServer) {
            this.navigateTo(firstActiveServer.url); // 自动跳转
          }
        }
        this.timeLeft--;
      }, 1000);
    },
    navigateTo(url) {
      window.location.href = url;
    },
  },
};
</script>


<style scoped>
body {
  font-family: "PingFang SC", "Microsoft YaHei", "Noto Sans CJK SC",
    "Source Han Sans SC", Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 40px;
  text-align: center;
}

header {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h1 {
  font-size: 1.4em;
  margin: 10px 0;
  color: #333;
}

h2 {
  font-size: 1.1em;
  margin: 10px 0;
  color: #444;
}

#results {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

button {
  padding: 15px 30px;
  font-size: 1.1em;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 340px;
  text-align: center;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
