<template>
  <div id="work" class="work-mode-list">
    <div class="filter-zone1">
      <el-input v-model="taskId" placeholder="请输入任务ID" maxlength="5" style="width: 150px; margin-right: 10px" clearable
        @keyup.enter="navigateToTask"></el-input>

      <el-button type="primary" @click="navigateToTask" :disabled="!isValidTaskId"
        style="margin-right: 16px">任务直达</el-button>
      <el-tooltip class="box-item" effect="dark" content="默认已按照【重要程度】自动排序" placement="bottom">
        <span class="advice">请优先完成【紧急】任务</span>
      </el-tooltip>

      <el-select v-model="selectedPriority" placeholder="优先级" clearable style="width: 6rem">
        <el-option label="紧急" value="紧急"></el-option>
        <el-option label="高" value="高"></el-option>
        <el-option label="一般" value="一般"></el-option>
      </el-select>
    </div>

    <div class="task-container">
      <TaskCard v-for="issue in filteredIssues" :key="issue.id" :task="issue" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios"; // 引入 axios
import TaskCard from "./base/TaskCard.vue";
import { ElMessage } from "element-plus"; // 引入 ElMessage

export default defineComponent({
  name: "WorkMode",
  components: {
    TaskCard,
  },
  data() {
    return {
      selectedPriority: null,
      issues: [],
      taskId: "", // 存储输入框中的任务ID
    };
  },
  computed: {
    filteredIssues() {
      return this.issues.filter((issue) => {
        const priorityMatch =
          !this.selectedPriority || issue.priority === this.selectedPriority;
        return priorityMatch;
      });
    },
    // 验证输入是否为有效的五位数数字
    isValidTaskId() {
      return /^\d{5}$/.test(this.taskId);
    },
  },
  created() {
    this.fetchIssues(); // 页面加载时获取数据
  },
  methods: {
    getApiHost() {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
        return 'hk.azlinux.com';
      }
      return 'sre.azlinux.com';
    },
    async fetchIssues() {
      let task_user = localStorage.getItem("email")?.substring(4);

      if (!task_user) {
        // 只弹出一次消息
        let countdown = 3; // 倒计时3秒
        ElMessage({
          message: `您尚未登录，请登录后使用。3秒内将跳转到登录页面。`,
          type: "error",
          duration: 3000, // 显示3秒
          offset: 240,
        });

        const interval = setInterval(() => {
          countdown--;
          if (countdown > 0) {
            ElMessage({
              message: `即将跳转，倒计时 ${countdown} 秒`,
              type: "info",
              duration: 1000,
              offset: 240,
            });
          } else {
            clearInterval(interval);
            window.location.href = "/login"; // 跳转到登录页面
          }
        }, 1000);

        return; // 终止函数，不继续发送请求
      }

      try {
        const response = await axios.get(
          `http://${this.getApiHost()}:5036/get_issue?task_user=${task_user}`
        );
        this.issues = response.data;

        console.log(this.issues);

        // 调用排序方法
        this.sortIssuesByPriority();

        this.updateAllIssueDates();
      } catch (error) {
        console.error("Failed to fetch issues:", error);
      }
    },
    sortIssuesByPriority() {
      const priorityWeight = {
        紧急: 1,
        高: 2,
        一般: 3,
      };

      this.issues.sort((a, b) => {
        const weightA = priorityWeight[a.priority] || 4; // 未定义的优先级默认为 4
        const weightB = priorityWeight[b.priority] || 4;
        return weightA - weightB; // 升序排序，权重小的排在前面
      });
    },
    updateAllIssueDates() {
      this.issues.forEach((issue, index) => {
        this.setIssueDate(index);
      });
    },
    setIssueDate(index) {
      if (!this.issues[index].issue_date) {
        const randomDate = this.getRandomDate();
        this.issues[index].issue_date = randomDate;
      }
    },
    getRandomDate() {
      const now = new Date();
      const fourHoursAgo = new Date(now.getTime() - 4 * 60 * 60 * 1000);
      const randomTimestamp =
        Math.random() * (now.getTime() - fourHoursAgo.getTime()) +
        fourHoursAgo.getTime();
      const randomDate = new Date(randomTimestamp);

      const formattedDate = randomDate
        .toLocaleString("zh-CN", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })
        .replace(/\//g, "-");
      return formattedDate;
    },
    // 根据输入的任务ID跳转到特定页面
    navigateToTask() {
      if (this.isValidTaskId) {
        const ipAddress = window.location.hostname; // 获取当前IP地址或主机名
        const port = window.location.port; // 获取当前端口号
        const taskUrl = `http://${ipAddress}:${port}/advanced/${this.taskId}`;
        window.location.href = taskUrl;
      } else {
        ElMessage.error("请输入有效的五位数任务ID");
      }
    },
  },
});
</script>

<style scoped>
.work-mode-list {
  padding: 1rem 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.filter-zone1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.advice {
  margin-right: 8px;
  font-size: 14px;
  color: gray;
}
</style>
