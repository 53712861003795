<template>
  <div class="outer">
    <div class="list-view">
      <div class="list-background">
        <list-item v-for="(item, index) in items" :key="index" :name="item.name" :componentToOpen="item.component"
          @item-clicked="handleItemClick" @mouseenter="playHoverSound" @mouseleave="stopHoverSound" />
      </div>
    </div>
    <div v-if="currentComponent" class="component-container">
      <component :is="currentComponent" />
    </div>
    <audio ref="hoverSound" src="http://cdn.azlinux.com/sre/media/hover.mp3" />
    <audio ref="clickSound" src="http://cdn.azlinux.com/sre/media/click.mp3" />
  </div>
</template>

<script>
import ListItem from "./tools/ListItem.vue";
import FirstComponent from "./tools/FirstComponent.vue";
import SecondComponent from "./tools/SecondComponent.vue";
import OpsSecurity from "./OpsSecurity.vue";

export default {
  components: {
    ListItem,
    FirstComponent,
    SecondComponent,
    OpsSecurity,
  },
  data() {
    return {
      items: [
        { name: "信息安全", component: "OpsSecurity" },
        { name: "运维小文章-技术版", component: "SecondComponent" },
        { name: "运维小文章-管理版", component: "FirstComponent" },
        { name: "Windows命令练习", component: "SecondComponent" },
        { name: "Windows在线环境", component: "FirstComponent" },
        { name: "通过游戏学Vim", component: "SecondComponent" },
        { name: "Component 7", component: "FirstComponent" },
        { name: "Component 8", component: "SecondComponent" },
      ],
      currentComponent: null,
    };
  },
  methods: {
    handleItemClick(component) {
      this.currentComponent = component;
      this.$refs.clickSound.play(); // 点击音效
    },
    playHoverSound() {
      this.$refs.hoverSound.currentTime = 0; // 从头播放
      this.$refs.hoverSound.play(); // 悬停音效
    },
    stopHoverSound() {
      this.$refs.hoverSound.pause(); // 停止悬停音效
    },
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  background-color: #222;
  /* 整个页面背景色 */
}

.outer {
  display: flex;
  /* 使用 flexbox */
  justify-content: flex-start;
  /* 左对齐 */
  background-color: #222;
}

.list-view {
  width: 300px;
  /* 列表宽度 */
  margin-left: 1rem;
  /* 左边距 */
  min-height: 100vh;
  /* 最小高度 */
}

.list-background {
  background: rgba(30, 30, 30, 0.9);
  /* 深色背景 */
  border-radius: 6px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.component-container {
  margin-left: 20px;
  /* 增加左边距以与列表分开 */
  padding: 10px;
  border: 1px solid #444;
  /* 边框颜色 */
  background-color: #222;
  /* 深色背景 */
  color: white;
  /* 字体颜色 */
}
</style>
