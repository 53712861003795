<template>
  <div class="user-center">
    <div class="section mynews">
      <h3>我的消息</h3>
      <h4 class="top-notes flashing-text">
        本网站服务器资源不可用于【运维练习】以外的用途！！！
      </h4>
      <div>
        <el-alert center>
          {{ profile.message }}
        </el-alert>
      </div>
    </div>
    <div class="section profile">
      <h3>个人资料和设置</h3>
      <el-form ref="profileForm" :model="profile" label-width="120px">
        <el-form-item label="电子邮件">
          <el-input v-model.trim="profile.email" readonly disabled></el-input>
        </el-form-item>
        <el-form-item label="运维昵称">
          <el-input v-model.trim="profile.nickname"></el-input>
        </el-form-item>
        <el-form-item label="设置sre密码">
          <el-input v-model="profile.sre_password" :type="passwordType" :maxlength="20"
            @input="validatePassword"></el-input>
          <span v-if="passwordError" style="color: red">密码只能包含大小写字母、数字、@ 和
            .(英文句号)。建议只修改数字部分为其他数字
          </span>
        </el-form-item>
        <el-form-item label="设置root密码">
          <el-input v-model="profile.root_password" :type="passwordType" disabled></el-input>
          <el-button @click="togglePasswordVisibility" type="text">查看sre和root密码</el-button>
        </el-form-item>
        <el-form-item label="设置账户新密码">
          <el-input v-model="profile.password" type="password" disabled></el-input>
        </el-form-item>
        <p style="color: teal; font-size: 14px; text-align: left; margin: 8px 0;">单向密码加密技术，管理员也无法看到您密码，无泄漏风险。</p>
        <el-button type="primary" @click="updateProfile">保存</el-button>
      </el-form>
    </div>

    <div class="section">
      <h3>会员状态</h3>
      <div v-if="profile.user_is_vip" class="vip-card">
        <VipCard :vipInfo="vipData" />
      </div>
      <div v-else>
        <RegularCard :vipInfo="vipData" />
      </div>

      <p>
        当前状态: {{ profile.user_is_vip }}
        <span v-if="profile.user_is_vip" class="vip-icon">★</span>
      </p>
      <p>结束时间: {{ profile.vip_end_datetime }}</p>
      <el-button type="primary" @click="buyVip">购买VIP</el-button>
    </div>

    <div class="section">
      <h3>云资源倾向</h3>
      <el-row>
        <el-radio-group v-model="cloudprovider">
          <el-radio label="autoselect">自动选择</el-radio>
          <el-radio label="alibaba">阿里云</el-radio>
          <el-radio label="huawei">华为云</el-radio>
          <el-radio label="aws">AWS</el-radio>
          <!-- <el-radio label="azure">Azure</el-radio>
          <el-radio label="gcp">GCP谷歌云</el-radio> -->
          <!-- <el-radio label="tencent">腾讯云</el-radio> -->
        </el-radio-group></el-row>
      <el-button type="primary" @click="saveCloudProvider">保存倾向</el-button>
    </div>

    <div class="section">
      <h3>主题设置</h3>
      <el-row>
        <el-radio-group v-model="profile.theme" @change="changeTheme">
          <el-radio label="red">热情红</el-radio>
          <el-radio label="green">青草绿</el-radio>
          <el-radio label="blue">天空蓝</el-radio>
          <el-radio label="game">游戏风格</el-radio>
        </el-radio-group></el-row>
      <el-button type="primary" @click="saveTheme">保存主题</el-button>
    </div>

    <div class="section">
      <h3>声音设置</h3>
      <el-row>
        <el-radio-group v-model="profile.sound" @change="changeSoundSettings">
          <el-radio label="no-sound">无</el-radio>
          <el-radio label="woman-sound">女士声音</el-radio>
          <el-radio label="man-sound">男士声音</el-radio>
        </el-radio-group></el-row>
      <el-button type="primary" @click="saveTheme">保存声音设置</el-button>
    </div>

    <div class="section">
      <h3>网页SSH终端设置</h3>
      <el-row>
        <el-radio-group v-model="profile.websshType" @change="changeWebSSHType">
          <el-radio label="default">默认</el-radio>
          <el-radio label="macos-style">MacOS风格</el-radio>
          <el-radio label="big-fonts-style">大字体风格</el-radio>
        </el-radio-group></el-row>
      <el-button type="primary" @click="saveTheme">保存终端设置</el-button>
    </div>
    <div class="section">
      <!-- Red line above -->
      <div class="danger-line"></div>
      <div class="button-container"> <!-- 新增的容器 -->
        <el-button type="primary" @click="logout">退出登录</el-button>
        <el-button type="warning" @click="refund">我要退款</el-button>
        <el-button type="danger" @click="deleteAccount">删除账号</el-button>
      </div>
      <!-- Red line below -->
      <div class="danger-line"></div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import VipCard from "./base/VipCard.vue";
import RegularCard from "./base/RegularCard.vue";

const axios = require("axios");

export default {
  name: "UserCenter",
  components: {
    VipCard,
    RegularCard,
  },
  data() {
    return {
      passwordError: false,
      value: ref(0),
      cloudprovider: ref("autoselect"),
      passwordType: "password", // 默认是密码不可见
      profile: {
        nickname: "",
        email: "sre@sre.azlinux.com",
        sre_password: "azLinux.com3",
        root_password: "",
        password: "",
        message: "",
        user_is_vip: false,
        vip_end_datetime: "",
        theme: "blue",
        sound: "no-sound",
        websshType: "default",
      },
      vipData: {
        title: "Membership Card",
        name: "Name",
        memberId: "0919 0029 2030 0000",
        validThru: "2030-12-31",
      },
    };
  },
  mounted() {
    const router = useRouter();
    const email = localStorage.getItem("email");

    if (!email || !email.startsWith("sre-")) {
      router.push("/login");
      return;
    } else {
      console.log("用户已登录，欢迎访问个人中心！");
    }

    const realEmail = email.substring(4);

    // 生成安全码
    const securityCode = this.generateSecurityCode(realEmail);

    const params = {
      email: realEmail,
      security_code: securityCode, // 添加安全码
    };

    axios
      .get(`http://${this.getApiHost()}:8096/get_user_info`, { params: params })
      .then((response) => {
        this.profile.message = response.data.message;
        this.profile.email = realEmail;
        this.profile.vip_end_datetime = response.data.vip_end_datetime;
        this.profile.nickname = response.data.username;
        this.profile.sre_password = response.data.sre_password;
        this.profile.root_password = response.data.root_password;
        this.profile.theme = response.data.theme;

        if (this.profile.nickname) {
          this.vipData.name = this.profile.nickname;
        }
        this.vipData.validThru = this.profile.vip_end_datetime.substring(0, 10);

        const currentDate = new Date();
        const vipEndDate = new Date(this.profile.vip_end_datetime);

        // 比较当前日期和 VIP 截止日期
        this.profile.user_is_vip = currentDate <= vipEndDate;
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        ElMessage({
          message: "获取用户信息失败，可忽略本次错误，或稍后重试。",
          type: "error",
          duration: 3000,
          offset: 120,
        });
      });
  },
  methods: {
    validatePassword() {
      const allowedChars = /^[A-Za-z0-9@.]+$/;
      this.passwordError = !allowedChars.test(this.profile.sre_password);
    },
    generateSecurityCode(email) {
      const localPart = email.split("@")[0];
      const asciiCodes = localPart
        .split("")
        .map((char) => char.charCodeAt(0))
        .join("");
      return asciiCodes + "B0rn";
    },
    togglePasswordVisibility() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    updateProfile() {
      const email = localStorage.getItem("email");
      const realEmail = email.substring(4);

      const params = {
        email: realEmail,
        username: this.profile.nickname,
        sre_password: this.profile.sre_password,
        root_password: this.profile.root_password,
      };

      axios
        .post(`http://${this.getApiHost()}:8096/update_user_info`, params)
        .then(function (response) {
          console.log(response);
          ElMessage({
            message: "保存成功。",
            type: "success",
            offset: 120,
          });
        })
        .catch(function (error) {
          console.log(error);
          ElMessage({
            message: "保存失败，建议稍后重试。",
            type: "error",
            offset: 120,
          });
        });
    },

    changeSoundSettings(value) {
      console.log("Change sound settings, change to", value);
    },

    changeWebSSHType(value) {
      console.log("Change webssh terminal style/settings, change to", value);
    },

    buyVip() {
      console.log("Buying VIP...");
      this.$router.push("/buy-vip");
    },
    refund() {
      console.log("Refund process ...");
    },
    changeTheme(value) {
      console.log("Changing theme to", value);
    },
    logout() {
      console.log("Logging out...");
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("token_type");
      localStorage.removeItem("expires_in");
      localStorage.removeItem("expiration_time");
      this.$router.push("/");
    },
    deleteAccount() {
      console.log("Delete account and user data...");
    },
    saveTheme() {
      console.log("Saving theme.");
    },
    saveCloudProvider(cloudprovider) {
      console.log("Save cloud provider to", cloudprovider);
    },
    getApiHost() {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
        return 'hk.azlinux.com';
      }
      return 'sre.azlinux.com';
    },
  },
};
</script>
<style scoped>
p {
  text-align: left;
  font-size: 14px;
}

.el-form {
  max-width: 20rem;
}

.el-alert {
  margin-top: 0.6rem;
}

.vip-icon {
  color: yellowgreen;
  font-size: 1.2em;
  margin-right: 5px;
}

.flashing-text {
  animation: flash 1.5s 3 alternate;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.user-center {
  margin: 2rem 6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 720px;
  max-width: 1020px;
  background-color: #f8fcfe;
  background-image: url("http://cdn.azlinux.com/sre/bg/user-center-blue-stars.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

h3 {
  text-align: left;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

h4 {
  margin: 0;
  font-size: 15px;
  padding: 0;
  font-weight: 600;
  text-align: left;
}

.section {
  margin-bottom: 1rem;
  padding-left: 2rem;
  max-width: 560px;
}

.el-button {
  margin-top: 0.5rem;
}

.danger-line {
  height: 1px;
  /* Adjust thickness as needed */
  background-color: red;
  /* Red color for the line */
  margin: 1rem 0;
  /* Space above and below the line */
}

.button-container {
  display: flex;
  justify-content: flex-end;
  /* 右对齐 */
  gap: 10px;
  /* 可选：按钮之间的间距 */
  margin-right: 0.4rem;
}
</style>
