<template>
  <div>
    <h4>服务器资源不可用于【运维练习】以外的用途！</h4>
    <div class="warning-alert">
      网站初期，谨慎充值。服务若故障，赠送您至少2倍服务天数。充值有次数限制。
    </div>

    <div class="buy-it">
      <el-row>
        <h3 style="margin: 4px 0; font-weight: 400; font-size: 16px">
          请选择
          (点击卡片，再点击【微信支付】或【支付宝】显示二维码)，价格会动态调整，如果降价会自动为用户延期使用时长:-)。
        </h3>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6" v-for="card in cards" :key="card.type">
          <el-card :class="['vip-card', { selected: card === selectedCard }]" :style="getCardStyle(card)"
            @click="selectCard(card)">
          </el-card>
          <div class="card-info">
            <p>{{ card.type }} 【¥ {{ card.price }} 元】</p>
            <p>总天数: {{ card.days }} 天</p>
          </div>
        </el-col>
      </el-row>

      <div v-if="selectedCard" class="payment-section">
        <p>
          感谢您选择【<span class="card-type"> {{ selectedCard.type }}</span>】，基准支付 【<span class="card-price">
            {{ selectedCard.price }}</span>】元人民币。
        </p>
        <p>建议的转账备注为【{{ userPrefix }}】或 【{{ userAccount }}】</p>
        <p style="color: slateblue; font-size: 13px">
          | 对价格不满意？使用频率低，可发送需求的邮件到站长，您指定价格！|
        </p>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-tooltip class="box-item" effect="dark" content="点击图片，显示二维码" placement="top">
              <img :src="isWechatQRCodeVisible ? wechatPaySrcQRCode : wechatPaySrc" alt="微信支付 Wechat Pay"
                @click="toggleWechatQRCode" class="pay-icon" style="
                  width: 240px;
                  height: 240px;
                  border: 4px solid #21a82f;
                  border-radius: 8px;
                  background-image: fit;
                " />
            </el-tooltip>
          </el-col>
          <el-col :span="6">
            <el-tooltip class="box-item" effect="dark" content="点击图片，显示二维码" placement="top">
              <img :src="isAlipayQRCodeVisible ? alipaySrcQRCode : alipaySrc" alt="支付宝 Alipay"
                @click="toggleAlipayQRCode" class="pay-icon" style="
                  width: 240px;
                  height: 240px;
                  border: 4px solid #1472f3;
                  border-radius: 8px;
                  background-image: fit;
                " />
            </el-tooltip>
          </el-col>
          <el-col :span="8" style="display: flex; align-items: flex-end">
            <div v-if="selectedCard" class="action-buttons">
              <el-button>充值记录</el-button>
              <el-button @click="cancelPayment">取消支付</el-button>
              <el-tooltip class="box-item" effect="dark" content="支付成功后，点击提交" placement="top">
                <el-button type="primary" @click="submitPaymentPre">我已完成支付</el-button>
              </el-tooltip>
            </div>
          </el-col>
        </el-row>
        <el-row style="text-align: center">
          <p style="
              text-align: center;
              font-size: 16px;
              margin-left: 1rem;
              margin-bottom: 6px;
            ">
            支持随时退款，退款入口：【个人中心】里能看到。
          </p>
        </el-row>
      </div>

      <el-dialog title="支付结果2次确认" v-model="dialogVisible" style="width: 20rem">
        <span style="font-weight: bold; font-size: 20px; color: orange">已支付成功？</span>
        <template v-slot:footer>
          <el-button @click="dialogVisible = false">否</el-button>
          <el-button type="primary" @click="submitPayment">是的</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      userAccount: "Your account",
      selectedCard: null,
      dialogVisible: false,
      isWechatQRCodeVisible: false, // 控制微信二维码显示的变量
      isAlipayQRCodeVisible: false, // 控制支付宝二维码显示的变量
      wechatPaySrc:
        "http://cdn.azlinux.com/sre/bg/wechat-pay-logo-sq.png",
      alipaySrc:
        "http://cdn.azlinux.com/sre/bg/alipay-logo-sq.png",
      wechatPaySrcQRCode:
        "http://cdn.azlinux.com/sre/bg/wechat_qr.png",
      alipaySrcQRCode:
        "http://cdn.azlinux.com/sre/bg/zhifubao_qr.png",
      cards: [
        {
          type: "一个月会员",
          payType: 1,
          price: 28,
          color: "#76A6C2",
          class: "monthly-card",
          days: 31,
          image:
            "http://cdn.azlinux.com/sre/bg/monthly-card.png",
        },
        {
          type: "季度会员",
          payType: 2,
          price: 50,
          color: "#FEF1B8",
          class: "quarterly-card",
          days: 93,
          image:
            "http://cdn.azlinux.com/sre/bg/quarterly-card.png",
        },
        {
          type: "半年会员",
          price: 100,
          payType: 3,
          color: "#D9E4B5",
          class: "halfyear-card",
          days: 186,
          image:
            "http://cdn.azlinux.com/sre/bg/half-year-card.png",
        },
        {
          type: "年度会员",
          payType: 4,
          price: 198,
          color: "#992023",
          class: "yearly-card",
          days: 372,
          image:
            "http://cdn.azlinux.com/sre/bg/annual-card.png",
        },
      ],
    };
  },
  computed: {
    userPrefix() {
      const atIndex = this.userAccount.indexOf("@");
      const prefixName = this.userAccount.substring(0, atIndex);

      return prefixName;
    },
  },
  mounted() {
    const email = localStorage.getItem("email");

    if (!email || !email.startsWith("sre-")) {
      return;
    }
    const realEmail = email.substring(4);
    this.userAccount = realEmail;
  },
  methods: {
    selectCard(card) {
      this.selectedCard = card;
    },
    cancelPayment() {
      this.selectedCard = null;
    },
    confirmPayment() {
      this.dialogVisible = true;
    },
    toggleWechatQRCode() {
      this.isWechatQRCodeVisible = !this.isWechatQRCodeVisible;
    },
    toggleAlipayQRCode() {
      this.isAlipayQRCodeVisible = !this.isAlipayQRCodeVisible;
    },
    submitPaymentPre() {
      this.dialogVisible = true;
      if (!this.selectedCard || !this.userAccount) {
        console.error("缺少必要的支付信息");
        return;
      }

      axios
        .post(`http://${this.getApiHost()}:5098/vip_user_pay_pre`, {
          amount: this.selectedCard.price,
          account: this.userAccount,
        })
        .then((response) => {
          console.log("支付成功", response.data);
        })
        .catch((error) => {
          // console.log(error.response.data.error);
          let responseData = error.response.data.error;
          ElMessage({
            message: responseData,
            type: "error",
            offset: 90,
          });
          if (error.response && error.response.status) {
            console.error(`错误状态码：${error.response.status}`);
          }
        });
    },
    submitPayment() {
      this.dialogVisible = false;
      if (!this.selectedCard || !this.userAccount) {
        console.error("缺少必要的支付信息");
        return;
      }

      axios
        .post(`http://${this.getApiHost()}:5098/vip_user_pay`, {
          amount: this.selectedCard.price,
          account: this.userAccount,
        })
        .then((response) => {
          console.log(
            response.data.message,
            response.data.new_vip_end_datetime
          );
          // console.log("支付成功", response.data.message);
          // console.log("支付成功", response.data.new_vip_end_datetime);
          ElMessage({
            message:
              "感谢您的支持！" +
              response.data.message +
              response.data.new_vip_end_datetime,
            type: "success",
            showClose: true,
            offset: 90,
          });
        })
        .catch((error) => {
          // 处理错误
          // console.error("支付失败", error);
          // console.log(error.response.data.error);
          let responseData = error.response.data.error;
          ElMessage({
            message: responseData,
            type: "error",
            offset: 120,
            showClose: true,
          });
          if (error.response && error.response.status) {
            console.error(`错误状态码：${error.response.status}`);
          }
        });
    },
    getCardStyle(card) {
      return {
        backgroundColor: card.color,
        backgroundImage: `url(${card.image})`, // 绑定背景图片
        backgroundSize: "cover", // 确保图片覆盖整个卡片
        backgroundPosition: "center", // 图片居中显示
      };
    },
    getApiHost() {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
        return 'hk.azlinux.com';
      }
      return 'sre.azlinux.com';
    },
  },
};
</script>

<style scoped>
h4 {
  padding: 6px 6px 0 6px;
  margin: 0;
  text-align: right;
  color: #ec407a;
  font-size: 16px;
  background-color: #ffebee;
}

.warning-alert {
  margin-bottom: 20px;
  text-align: right;
  font-size: 14px;
  padding: 10px 20px 10px;
  background-color: #ffebee;
  color: #e57373;
}

.card-type {
  font-style: bold;
  color: red;
}

.card-price {
  font-style: bold;
  color: red;
}

.buy-it {
  padding-left: 2rem;
  padding-right: 2rem;
}

.vip-card {
  text-align: center;
  cursor: pointer;
  /* 确保文字在背景图片上可读，可以根据需要调整 */
  color: white;
  font-weight: bold;
  border-radius: 8px;
  /* width: 280px; */
  height: 192px;
  width: 300px;
  background-size: cover;
}

.payment-section {
  margin-top: 16px;
}

.pay-icon {
  width: 360px;
  height: 360px;
  /* cursor: pointer; */
}

.pay-icon:hover {
  cursor: pointer;
}

.action-buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.card-info {
  text-align: center;
  /* 居中对齐文字 */
  margin-top: 10px;
  /* 根据需要调整与卡片的间距 */
}

.card-info p {
  margin: 5px 0;
  /* 调整段落间距 */
  color: #333;
  /* 根据需要设置文字颜色 */
  font-size: 15px;
}

.payment-section {
  font-size: 15px;
}

.dialog-footer {
  text-align: right;
}

.vip-card.selected {
  border: 4px solid pink;
  box-shadow: 5px 5px 5px red;
}
</style>
