<template>
  <div class="global">
    <h3 v-for="(char, index) in typingText" :key="index">
      {{ char }}
    </h3>
    <div class="image-container">
      <el-row :gutter="20">
        <el-col :span="16" class="hover-effect">
          <el-image :src="pic1" alt="图片1" fit="cover" class="image-hover" />
          <div class="image-description">{{ descriptions[0] }}</div>
        </el-col>
        <el-col :span="8" class="hover-effect">
          <el-image :src="pic2" alt="图片2" fit="cover" class="image-hover" />
          <div class="image-description">{{ descriptions[1] }}</div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8" class="hover-effect">
          <el-image :src="pic3" alt="图片3" fit="cover" class="image-hover" />
          <div class="image-description">{{ descriptions[2] }}</div>
        </el-col>
        <el-col :span="16" class="hover-effect">
          <el-image :src="pic4" alt="图片4" fit="cover" class="image-hover" />
          <div class="image-description">{{ descriptions[3] }}</div>
        </el-col>
      </el-row>
    </div>
    <div class="quotes-container">
      <div class="quote" id="quote1">
        <p>“科学的唯一目的，就是减轻人类生存的苦难。”</p>
      </div>
      <div class="quote" id="quote2">
        <p>“Keep learning”</p>
      </div>
      <div class="quote" id="quote3">
        <p>“勤能补拙是良训，一分辛苦一分才”</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pic1: "http://cdn.azlinux.com/sre/bg/pic1.jpg", // 图片1的路径
      pic2: "http://cdn.azlinux.com/sre/bg/pic2.jpg", // 图片2的路径
      pic3: "http://cdn.azlinux.com/sre/bg/pic3.jpg", // 图片3的路径
      pic4: "http://cdn.azlinux.com/sre/bg/pic4.jpg", // 图片4的路径
      descriptions: [
        "厚实的理论基础 + 丰富的生产级运维实践，技能和薪资齐飞！",
        "丰富完备的运维平台，收纳了业界领先的运维实践，工具！自动化！效率！",
        "熟能生巧，长年累月，具备运维架构设计能力。",
        "知识点【通关模式】设计，在线连接环境实战操作后，如果通关，则获得能力积分。",
      ],
      fullText: "运维练兵场：丰富的生产级实战环境，迅速提升工程师实战水平！",
      typingText: "",
    };
  },
  mounted() {
    let i = 0;
    const interval = setInterval(() => {
      this.typingText = this.fullText.slice(0, i + 1);
      i++;
      if (i === this.fullText.length) {
        clearInterval(interval);
      }
    }, 100); // 调整间隔时间
  },
};
</script>

<style scoped>
h3 {
  margin-top: 1.6rem;
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
  color: #444;
  /* 或者你喜欢的任何颜色 */
  line-height: 2.2;
  font-family: Inter, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

h3::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;
  /* 调整下划线和文字之间的距离 */
  height: 4px;
  background-color: gold;
  background-size: 600% 600%;
  animation: shine 4s linear infinite;
}

@keyframes shine {
  0% {
    background-position: 0 0;
  }

  50% {
    /* background-position: 400% 0; */
    height: 0.6rem;
  }

  100% {
    /* background-position: 600% 0; */
    height: 0.8rem;
  }
}

/* .hover-effect:hover .image-hover {
  transform: scale(1.05);
  transition: transform 0.8s ease-in-out;
} */

.hover-effect:hover .image-description {
  font-weight: bold;
  /* 文字变粗 */
}

.image-container {
  margin: 2rem 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 这会让子元素（即el-row）在水平方向上居中 */
}

.image-description {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  font-size: 1rem;
  color: #555;
  font-weight: 300;
}

.el-image {
  height: 420px;
  border-radius: 1rem;
}

/* 响应式调整 */
@media (max-width: 1200px) {
  .el-image {
    height: 340px;
    /* 在较大屏幕上稍微减小高度 */
  }
}

@media (max-width: 768px) {
  .el-image {
    height: 240px;
    /* 在较小屏幕上使用更小的高度 */
  }
}

.quotes-container {
  width: 100%;
  max-width: 600px;
  /* 根据需要调整容器宽度 */
  margin: auto;
  /* 居中显示 */
  padding: 20px;
}

.quote {
  margin-bottom: 20px;
  /* 每个quote之间的间距 */
  padding: 15px;
  background-color: #f9f9f9;
  /* 浅灰色背景 */
  border-left: 5px solid #4caf50;
  /* 左边框 */
  position: relative;
  /* 为后续可能的样式调整做准备 */
}

.quote p {
  font-family: Inter, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 1.1rem;
  /* 字体大小 */
  color: #333;
  /* 字体颜色 */
  margin: 0;
  /* 去除默认的段落间距 */
  font-style: italic;
}

#quote1 {
  margin-left: 0rem;
}

#quote2 {
  margin-left: 4rem;
}

#quote3 {
  margin-left: 8rem;
}
</style>
