<template>
  <div class="portal">
    <div class="dashboard">
      <div class="dashboard-card health-status">
        <h3>环境健康状态</h3>
        <p>
          生产环境：<span class="status-ok">正常</span>
          <a href="https://status.azlinux.com/status/uptime" target="_blank"
            style="margin-left: 10px; font-size: 14px; color: darkgreen">所有服务</a>
        </p>
        <p>准生产环境：<span class="status-ok">正常</span></p>
        <p>开发环境：<span class="status-warning">注意</span></p>
      </div>
      <div class="dashboard-card alert-status">
        <h3>系统告警</h3>
        <p>当前无重大告警</p>
      </div>
    </div>

    <div class="tool-section">
      <h2 style="text-align: left; margin: 12px 0">运维工具</h2>
      <div class="tool-grid">
        <div v-for="tool in tools" :key="tool.linkText" class="tool-card">
          <img v-if="tool.img" :src="tool.img" class="tool-card-image" />
          <h4>{{ tool.title }}</h4>
          <div class="tool-description">{{ tool.introduction }}</div>
          <a :href="tool.link">{{ tool.linkText }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tools: [
        {
          title: "变更管理系统",
          introduction:
            "用于管理和记录对生产环境的所有修改操作，确保变更的控制、审核和审批流程。",
          link: "http://change.azlinux.com",
          linkText: "change.azlinux.com",
          backgroundColor: "#e8f5e9",
          img: "http://cdn.azlinux.com/sre/media/sre_change.png", // 示例图片
        },
        {
          title: "堡垒机",
          introduction:
            "用于授权和控制用户对企业生产环境的访问，审计用户的操作行为和权限。",
          link: "http://bastion.azlinux.com",
          linkText: "bastion.azlinux.com",
          backgroundColor: "#f3e5f5",
          img: "http://cdn.azlinux.com/sre/media/sre_bastion.png",
        },
        {
          title: "告警中心",
          introduction:
            "集中处理来自各个监控工具的告警信息，并对告警事件发出通知，如邮件、短信、电话等。",
          link: "http://alarm.azlinux.com",
          linkText: "alarm.azlinux.com",
          backgroundColor: "#ffe0b2",
          img: "http://cdn.azlinux.com/sre/media/sre_alert.png",
        },
        {
          title: "ITSM",
          introduction:
            "提供运维业务流程的标准化管理，包括运维请求的发起、审批和跟踪等功能。",
          link: "http://itsm.azlinux.com",
          linkText: "itsm.azlinux.com",
          backgroundColor: "#fafafa",
          img: "http://cdn.azlinux.com/sre/media/sre_itsm.png",
        },
        {
          title: "CMDB",
          introduction:
            "配置管理数据库，用于记录和管理IT资产的信息，如服务器硬件、网络IP等。",
          link: "http://cmdb.azlinux.com",
          linkText: "cmdb.azlinux.com",
          backgroundColor: "#e3f2fd",
          img: "http://cdn.azlinux.com/sre/media/sre_cmdb.png",
        },
        {
          title: "日志中心",
          introduction: "为接入日志中心的应用提供集中化的日志存储和查询功能。",
          link: "http://logs.azlinux.com",
          linkText: "logs.azlinux.com",
          backgroundColor: "#fff3e0",
          img: "http://cdn.azlinux.com/sre/media/sre_logs.png",
        },
        {
          title: "监控中心",
          introduction:
            "收集、整合和分析各类监控数据，以确保系统的稳定性和性能。",
          link: "http://mon.azlinux.com",
          linkText: "mon.azlinux.com",
          backgroundColor: "#fce4ec",
          img: "http://cdn.azlinux.com/sre/media/sre_bastion.png",
        },
        {
          title: "运维日历",
          introduction:
            "记录和规划公司的运维活动，包括定期上线、运维人员值班状态等。",
          link: "http://cal.azlinux.com",
          linkText: "cal.azlinux.com",
          backgroundColor: "#e1bee7",
          img: "http://cdn.azlinux.com/sre/media/sre_cal.png",
        },
        {
          title: "文档中心",
          introduction: "用于存储和管理公司工作文档和知识库。",
          link: "http://wiki.azlinux.com",
          linkText: "wiki.azlinux.com",
          backgroundColor: "#c8e6c9",
          img: "http://cdn.azlinux.com/sre/media/sre_wiki.png",
        },
        {
          title: "代码中心",
          introduction: "提供代码托管和版本控制服务，例如 GitLab。",
          link: "http://gitlab.azlinux.com",
          linkText: "code.azlinux.com",
          backgroundColor: "#d1c4e9", // 浅紫色
          img: "http://cdn.azlinux.com/sre/media/sre_code.png",
        },
        {
          title: "下载中心",
          introduction: "提供安全的软件下载，杜绝不明来源的软件隐患。",
          link: "http://dl.azlinux.com",
          linkText: "dl.azlinux.com",
          backgroundColor: "#80deea", // 浅紫色
          img: "http://cdn.azlinux.com/sre/media/sre_download.png",
        },
        {
          title: "安全扫描中心",
          introduction: "对主机和应用进行安全扫描，并提供安全建议和漏洞修复。",
          link: "http://scan.azlinux.com",
          linkText: "scan.azlinux.com",
          backgroundColor: "#b2dfdb", // 浅青色
          img: "http://cdn.azlinux.com/sre/media/sre_scan.png",
        },
        {
          title: "自动化部署系统",
          introduction:
            "用于自动化软件部署和配置管理，如 Jenkins、Ansible、Terraform。",
          link: "http://auto.azlinux.com",
          linkText: "auto.azlinux.com",
          backgroundColor: "#f0f4c3", // 浅黄绿色
          img: "http://cdn.azlinux.com/sre/media/sre_auto.png",
        },
        {
          title: "性能监控系统",
          introduction: "监控和可视化系统性能指标，如 Prometheus、Grafana。",
          link: "http://perf.azlinux.com",
          linkText: "perf.azlinux.com",
          backgroundColor: "#ffccbc", // 浅橙色
          img: "http://cdn.azlinux.com/sre/media/sre_perf.png",
        },
        {
          title: "容量规划和管理工具",
          introduction:
            "帮助计划和管理系统资源的使用情况，如 Netdata、Capacity Planner。",
          link: "http://cap.azlinux.com",
          linkText: "cap.azlinux.com",
          backgroundColor: "#e1f5fe", // 浅蓝色
          img: "http://cdn.azlinux.com/sre/media/sre_plan.png",
        },
        {
          title: "服务管理平台",
          introduction:
            "提供全面的服务管理和IT业务管理功能，如 ServiceNow、BMC Remedy。",
          link: "http://service.azlinux.com",
          linkText: "service.azlinux.com",
          backgroundColor: "#ffebee", // 浅红色
          img: "http://cdn.azlinux.com/sre/media/sre_svc.png",
        },
        {
          title: "配置管理平台",
          introduction:
            "自动化配置管理工具，如 Puppet、Chef，确保一致性和合规性。",
          link: "http://config.azlinux.com",
          linkText: "config.azlinux.com",
          backgroundColor: "#c5e1a5", // 浅绿黄色
          img: "http://cdn.azlinux.com/sre/media/sre_conf.png",
        },
        {
          title: "网络监控工具",
          introduction: "监控网络设备和服务状态，如 Nagios、Zabbix。",
          link: "http://network.azlinux.com",
          linkText: "network.azlinux.com",
          backgroundColor: "#dcedc8", // 浅绿黄色
          img: "http://cdn.azlinux.com/sre/media/sre_network.png",
        },
        {
          title: "故障管理系统",
          introduction: "管理和响应系统故障和事件，如 PagerDuty、Opsgenie。",
          link: "http://incident.azlinux.com",
          linkText: "incident.azlinux.com",
          backgroundColor: "#f8bbd0", // 浅粉色
          img: "http://cdn.azlinux.com/sre/media/sre_recover.png",
        },
        {
          title: "资产管理平台",
          introduction: "记录和管理IT资产的信息，如 Lansweeper、Snipe-IT。",
          link: "http://asset.azlinux.com",
          linkText: "asset.azlinux.com",
          backgroundColor: "#f5f5f5", // 浅灰色
          img: "http://cdn.azlinux.com/sre/media/sre_assets.png",
        },
        {
          title: "备份和恢复",
          introduction: "管理数据备份和恢复操作，如 Veeam、Backup Exec。",
          link: "http://backup.azlinux.com",
          linkText: "backup.azlinux.com",
          backgroundColor: "#f0f4c3", // 浅黄绿色
          img: "http://cdn.azlinux.com/sre/media/sre_restore.png",
        },
        {
          title: "数据分析与报告",
          introduction: "商业智能BI平台，分析产品质量，运维质量等",
          link: "http://bi.azlinux.com",
          linkText: "bi.azlinux.com",
          backgroundColor: "#e0f7fa", // 浅青色
          img: "http://cdn.azlinux.com/sre/media/sre_bi.png",
        },
      ],
    };
  },
};
</script>

<style scoped>
.portal {
  margin: 1.2rem 4.8rem;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.portal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #003366;
  color: white;
}

.portal-header .logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.portal-header .search-bar {
  flex-grow: 1;
  margin-left: 2rem;
}

.portal-header .user-menu {
  display: flex;
  align-items: center;
}

.dashboard {
  display: flex;
  /* justify-content: space-between; */
  padding: 0.4rem;
  gap: 20px;
}

.dashboard-card {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 30%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-card h3 {
  margin-bottom: 1rem;
  color: #003366;
  font-size: 1.5rem;
}

.dashboard-card p {
  font-size: 1.1rem;
  margin-bottom: 8px;
  margin-top: 8px;
}

.dashboard-card a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.dashboard-card a:hover {
  text-decoration: underline;
}

.dashboard-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* 特殊状态的样式 */
.status-ok {
  color: #28a745;
  font-weight: bold;
}

.status-warning {
  color: #ff9800;
  font-weight: bold;
}

.health-status {
  background: linear-gradient(135deg, #e0f7fa, #80deea);
}

.alert-status {
  background: linear-gradient(135deg, #fff3e0, #ffcc80);
}

.quick-access {
  background: linear-gradient(135deg, #f1f8e9, #aed581);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    gap: 1rem;
  }

  .dashboard-card {
    width: 100%;
  }
}

.tool-section {
  padding: 1rem;
}

.tool-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.tool-card {
  position: relative;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: calc(33.333% - 20px);
  box-sizing: border-box;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.tool-card h4 {
  margin: 0.5rem 0;
  color: #003366;
  font-size: 1.25rem;
}

.tool-card .tool-description {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.tool-card a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.tool-card a:hover {
  text-decoration: underline;
}

.tool-card-image {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  /* 调整图片大小 */
  height: auto;
  opacity: 0.8;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }

  .dashboard-card {
    width: 100%;
    margin-bottom: 1rem;
  }

  .tool-card {
    width: 100%;
  }
}
</style>
