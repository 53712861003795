<template>
  <div class="outer">
    <el-tooltip class="box-item" effect="dark" content="默认1小时后释放，如果您想继续使用，可以延期释放。" placement="bottom">
      <el-button plain @click="handleDelayRelease" :disabled="!delayReleaseButtonEnabled">延期释放</el-button></el-tooltip>
    <el-dialog v-model="dialogVisible" title="【延期释放】" width="360px">
      <div class="dialog-warning">不得将本站资源用于运维练习以外的用途。</div>
      <div class="dialog-content">
        <el-select v-model="delayTime" placeholder="请选择延期时长" class="delay-select">
          <el-option label="30 分钟" value="30" />
          <el-option label="60 分钟" value="60" />
          <el-option label="90 分钟" value="90" disabled />
          <el-option label="120 分钟" value="120" disabled />
        </el-select>
      </div>
      <div slot:footer class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmDelay">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
  emits: ["updateReleaseTime"],
  data() {
    return {
      dialogVisible: false,
      delayTime: 30,
      email: "your_email@example.com",
      newReleaseTime: "",
    };
  },
  props: {
    delayReleaseButtonEnabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleDelayRelease() {
      this.dialogVisible = true;
    },
    confirmDelay() {
      const email_user = localStorage.getItem("email");
      if (!email_user) {
        ElMessage({
          message: "发生了异常，建议退出账号，重新登录",
          type: "error",
          showClose: true,
          duration: 2000,
          offset: 240,
        });
        return;
      }

      // 发送 POST 请求
      axios
        .post(`http://${this.getApiHost()}:5092/delay_release`, {
          email: email_user.substring(4),
          delay_time: this.delayTime,
        })
        .then((response) => {
          if (response.data.error === "") {
            this.newReleaseTime = response.data.new_end_time;
            console.log(this.newReleaseTime);
            console.log(response.data.message);
            ElMessage({
              message: "延期成功, 新释放时间: " + response.data.new_end_time,
              type: "success",
              showClose: true,
              duration: 2000,
              offset: 240,
            });
            //emit data to parent
            this.$emit("updateReleaseTime", this.newReleaseTime);
          } else {
            ElMessage({
              message: "延期失败: " + response.data.error,
              type: "error",
              showClose: true,
              duration: 2000,
              offset: 240,
            });
          }
          this.dialogVisible = false;
        })
        .catch((error) => {
          // console.error(error);
          ElMessage({
            message: "请求失败: " + error.response.data.error,
            type: "error",
            showClose: true,
            duration: 2000,
            offset: 240,
          });
          this.dialogVisible = false;
        });
    },
    getApiHost() {
      const hostname = window.location.hostname;
      if (hostname === 'sre.azlinux.com' || hostname === 'cn.sre.azlinux.com') {
        return 'sre.azlinux.com';
      }
      if (hostname === 'hk.sre.azlinux.com' || hostname === 'hk.azlinux.com') {
        return 'hk.azlinux.com';
      }
      return 'sre.azlinux.com';
    },
  },
};
</script>

<style scoped>
.dialog-warning {
  background-color: #fdf6f6;
  /* 警告背景色 */
  color: #f56c6c;
  /* 警告文字颜色 */
  padding: 10px;
  font-size: 14px;
  text-align: center;
}

.dialog-content {
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.delay-select {
  width: 10rem;
}

.el-dialog__body {
  padding: 0;
}

.dialog-footer {
  text-align: right;
  padding: 10px 20px;
}

.dialog-footer .el-button+.el-button {
  margin-left: 10px;
}

.el-button {
  margin: 0 2px;
  padding: 2px;
}
</style>
